import { message } from "antd";
import { API_BASE_URL } from "configs/AppConfig.js";
import i18nIsoCountries from "i18n-iso-countries";
import fetch_ from "interceptors/FetchInterceptor";
import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AssistanceChatBotContext = createContext();

const AssistanceChatBotProvider = ({ children }) => {
  const userInfoString = localStorage.getItem("userInfo");
  const history = useHistory();
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const [chatType, setChatType] = useState("");
  const topRef = useRef(null);

  const [messages, setMessages] = useState([
    // {
    //     sender: "receiver",
    //     text: "Hi, I'm Lenny, your friendly AI assistant here to help.",
    // },
    // {
    //     sender: "user",
    //     text: "Welcome",
    // }
  ]);
  const [render, setRender] = useState(true);
  const [startChat, setStartChat] = useState(false);
  const [enableChat, setEnableChat] = useState(false);
  const [hideIcons, setHideIcons] = useState(false);
  const [maximizeView, setMaximizeView] = useState(false);

  const handleScrollToTop = () => {
    // window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const setChatTypeFn = (type) => {
    setChatType(type);
  };

  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [citizenship, setCitizenship] = useState([]);
  const [ethnicity, setEthnicity] = useState([]);
  const [schoolLevel, setSchoolLevel] = useState([]);
  const [militaryAffiliation, setMilitaryAffiliation] = useState([]);
  const [gpa, setGpa] = useState([]);
  const [highSchoolName, setHighSchoolName] = useState([]);
  const [collegeName, setCollegeName] = useState([]);
  const [fieldOfStudy, setFieldOfStudy] = useState([]);
  const [degreeType, setDegreeType] = useState([]);
  const [careerGoal, setCareerGoal] = useState([]);
  const [citizenshipId, setCitizenshipId] = useState();
  const [ethnicityId, setEthnicityId] = useState();
  const [countryId, setCountryId] = useState();
  const [highSchoolNameId, setHighSchoolNameId] = useState();
  const [militaryAffiliationId, setMilitaryAffiliationId] = useState();
  const [gpaId, setGpaId] = useState();
  const [degreeTypeId, setDegreeTypeId] = useState();
  const [careerGoalId, setCareerGoalId] = useState();
  const [highSchoolGradDateId, setHighSchoolGradDateId] = useState();
  const [enrolledInCollegeId, setEnrolledInCollegeId] = useState();
  const [collegeEnrolledDateId, setCollegeEnrolledDateId] = useState();
  const [collegeNameId, setCollegeNameId] = useState();
  const [genderId, setGenderId] = useState();
  const [fieldOfStudyId, setFieldOfStudyId] = useState();
  const [currentSchoolLevelId, setCurrentSchoolLevelId] = useState();
  const [zipCodeId, setZipCodeId] = useState();
  const [collegeGraduationDateId, setCollegeGraduationDateId] = useState();
  const [interestedInOnlineId, setInterestedInOnlineId] = useState();
  const [interestedCollegesId, setInterestedCollegesId] = useState();

  const [numberForCountryName, setNumberForCountryName] = useState();
  i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

  // Get the stored country code
  const shortCountryName = localStorage.getItem("shortCountry") ?? "us";
  const [country, setCountry] = useState([]);
  const countryName = i18nIsoCountries.getName(shortCountryName, "en") || "USA";

  const [hideNavAlert, setHideNavAlert] = useState(false);
  const [localData, setLocalData] = useState(false);
  const [assMessages, setAssMessages] = useState([]);
  const [qusCounter, setQusCounter] = useState(0);
  const [forceStopRender, setForceStopREnder] = useState(true);
  const [chatBtn, setChatBtn] = useState(true);
  const [reqAssQusArr, setReqQusArr] = useState([]);
  const [filledData, setFilledData] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [chatBtnType, setChatBtnType] = useState("");
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [reqAssMaxView, setReqMaxView] = useState(false);
  const [mobReqAssMaxView, setMobReqMaxView] = useState(false);
  const [hint, setHint] = useState("");
  const [first, setFirst] = useState(true);
  const [applyScholarshipId, setApplyScholarshipId] = useState("");
  const [chatAssBtn, setChatAssBtn] = useState(false);

  const [runTour, setRunTour] = useState(false);
  const [steps, setSteps] = useState([]);
  const [currentRequirementIndex, setCurrentRequirementIndex] = useState(0);
  const [saveAndNext, setSaveAndNext] = useState(false);
  const [wordLengthData, setWordLengthData] = useState(0);

  const [freeScholarshipCount, setFreeScholarshipCount] = useState(3);
  const [canShare, setCanShare] = useState(false);
  const [invitationPopUp, setInvitationPopUp] = useState(false);
  const [stopRender, setStopRender] = useState(true);

  const viewSelector = [
    "/",
    "/home",
    "/b2b",
    "/auth/",
    "/auth/login",
    "/auth/register",
    "/auth/register1",
    "/signup",
    "/auth/userdetails",
    "/auth/apply",
    "/privacy",
    "/terms",
    "/faq",
    "/contact",
    "/partners",
    "/pricing",
    "/about-us",
    "/chatbot",
    "/forgot-password",
    "/lennyAssistance",
    "/unsubscribe",
    "/lenny-terms-policy",
    "/do-not-sell-my-personal-information",
  ].includes(window.location.pathname);

  const getFreeScholarshipCount = async () => {
    if (!viewSelector) {
      try {
        const result = await fetch_({
          url: "/register-applicants/scholarship-limit",
          method: "GET",
        });
        if (result.statusCode === 200) {
          setFreeScholarshipCount(result?.data?.freeScholarshipLimit || 3);
        } else {
          console.error("Failed to fetch scholarship limit:", result);
          setFreeScholarshipCount(3);
        }
      } catch (error) {
        // Handle any errors that occurred during the fetch
        console.error("Error fetching scholarship limit:", error);
        // Optionally, set a default value or handle it in some other way
        setFreeScholarshipCount(3);
      }
    }
  };
  const updateScholarshipLimit = async (limit) => {
    const payload = {
      newLimit: limit,
    };
    try {
      const result = await fetch_({
        url: "/register-applicants/update-scholarship-limit",
        method: "POST",
        data: payload,
      });
      console.log(result);
      if (result.statusCode === 200) {
        setFreeScholarshipCount(result?.data?.freeScholarshipLimit || 3);
      } else {
        console.error("Failed to fetch scholarship limit:", result);
        setFreeScholarshipCount(3);
      }
    } catch (error) {
      console.error("Error fetching scholarship limit:", error);
      setFreeScholarshipCount(3);
    }
  };
  const checkCanShare = async (skipPopup = false) => {
    try {
      const result = await fetch_({
        url: "/register-applicants/can-share",
        method: "GET",
      });
      if (result.statusCode === 200) {
        setCanShare(() => {
          return result?.data?.canShare;
        });

        if (!skipPopup) {
          setInvitationPopUp(result?.data?.canShare);
        }
        return result?.data?.canShare;
      } else {
        setCanShare(false);
        if (!skipPopup) {
          setInvitationPopUp(false);
        }
        return false;
      }
    } catch (error) {
      console.log("🚀 ~ checkCanShare ~ error:", error);
      setInvitationPopUp(false);
    }
  };

  useEffect(() => {
    getFreeScholarshipCount();
    // updateScholarshipLimit(1)
    // checkCanShare()
  }, []);

  const startTour = (tourSteps) => {
    setSteps(tourSteps);
    setRunTour(true);
  };

  const endTour = () => {
    setRunTour(false);
    setSteps([]);
  };

  useEffect(() => {
    fetch(`${API_BASE_URL}api/v1/register-applicants/form-fields`)
      .then((response) => response.json())
      .then((res) => {
        const res_ = res.data;
        setData(res_);
        setLoading(false);
        setCitizenship(
          res_?.filter((e) => e.key === "citizenship")[0]?.options
        );
        setEthnicity(res_?.filter((e) => e.key === "ethnicity")[0]?.options);
        setSchoolLevel(
          res_?.filter((e) => e.key === "schoolLevel")[0]?.options
        );
        setMilitaryAffiliation(
          res_?.filter((e) => e.key === "militaryAffiliation")[0]?.options
        );
        setGpa(res_?.filter((e) => e.key === "gpa")[0]?.options);
        setHighSchoolName(
          res_?.filter((e) => e.key === "highSchoolName")[0]?.options
        );

        // console.log("country", country);
        setCollegeName(
          res_?.filter((e) => e.key === "collegeName")[0]?.options
        );
        setFieldOfStudy(
          res_?.filter((e) => e.key === "fieldOfStudy")[0]?.options
        );
        setDegreeType(res_?.filter((e) => e.key === "degreeType")[0]?.options);
        setCareerGoal(res_?.filter((e) => e.key === "careerGoal")[0]?.options);
        setCitizenshipId(res_?.filter((e) => e.key === "citizenship"));
        setEthnicityId(res_?.filter((e) => e.key === "ethnicity"));
        setCountryId(res_?.filter((e) => e.key === "country"));
        setCountry(res_?.filter((e) => e.key === "country")[0]?.options || {});

        setHighSchoolNameId(res_?.filter((e) => e.key === "highSchoolName"));
        setMilitaryAffiliationId(
          res_?.filter((e) => e.key === "militaryAffiliation")
        );
        setGpaId(res_?.filter((e) => e.key === "gpa"));
        setDegreeTypeId(res_?.filter((e) => e.key === "degreeType"));
        setCareerGoalId(res_?.filter((e) => e.key === "careerGoal"));
        setHighSchoolGradDateId(
          res_?.filter((e) => e.key === "highSchoolGraduationDate")
        );
        setEnrolledInCollegeId(
          res_?.filter((e) => e.key === "enrolledInCollege")
        );
        setCollegeEnrolledDateId(
          res_?.filter((e) => e.key === "enrollmentDate")
        );
        setCollegeNameId(res_?.filter((e) => e.key === "collegeName"));
        setGenderId(res_?.filter((e) => e.key === "gender"));
        setFieldOfStudyId(res_?.filter((e) => e.key === "fieldOfStudy"));
        setCurrentSchoolLevelId(res_?.filter((e) => e.key === "schoolLevel"));
        setZipCodeId(res_?.filter((e) => e.key === "zipCode"));
        setCollegeGraduationDateId(
          res_?.filter((e) => e.key === "collegeGraduationDate")
        );
        setInterestedInOnlineId(
          res_?.filter((e) => e.key === "isInterestedInOnlineStudy")
        );
        setInterestedCollegesId(
          res_?.filter((e) => e.key === "interestedColleges")
        );
        const country_list =
          res_?.filter((e) => e.key === "country")[0]?.options || {};
        setNumberForCountryName(
          Object.keys(country_list).find(
            (number) => country_list[number] === countryName
          ) || "1"
        );
      })
      .catch((error) => {
        console.error("Fetching error:", error);
        // setError(error);
        setLoading(false);
      })
      .finally((_) => setLoading(false));
  }, []);
  const chatThreeDotLoading = async (
    skipCall,
    apiCall,
    msg,
    chatBtnState = true
  ) => {
    // if (skipCall) {
    // Display the loading message
    const loadingMessage = {
      sender: "receiver",
      text: "loading",
    };
    setAssMessages((prevMessages) => [...prevMessages, loadingMessage]);
    // }
    try {
      // Wait for the API call to complete
      let responseText;
      if (skipCall) {
        responseText = msg;
      } else {
        responseText = await apiCall();
      }

      const updateMessages = () => {
        setAssMessages((prevMessages) => {
          // Remove the loading message if it exists
          const newMessages = prevMessages.filter((e) => e.text !== "loading");
          // Add the new message with the API response
          return [...newMessages, { sender: "receiver", text: responseText }];
        });
      };

      if (skipCall) {
        setTimeout(updateMessages, 500);
      } else {
        updateMessages();
      }

      setChatBtn(chatBtnState);
    } catch (error) {
      // Handle any errors
      console.error("API call failed:", error);

      setAssMessages((prevMessages) => {
        // Remove the loading message if it exists
        const newMessages = prevMessages.filter((e) => e.text !== "loading");
        // Add an error message
        return [
          ...newMessages,
          { sender: "receiver", text: "Error loading response" },
        ];
      });

      setChatBtn(true);
    }
  };

  const askNextQus = (fieldKey) => {
    let qusArr = reqAssQusArr.filter((e) => e.key === fieldKey);
    setAssMessages(() => []);

    if (qusArr[0]?.name) {
      chatThreeDotLoading(true, null, [
        {
          chat_type: "select_yes_no",
          data: `Oh! I see, you need to write ${qusArr[0]?.name} to apply on this scholarship. Do you need any help?`,
        },
      ]);
    }

    // if (qusCounter < reqAssQusArr.length - 1) {
    //   // console.log(chatBtnType)
    //   // let qusArr = [];

    //   // if (chatBtnType) {
    //   //   qusArr = reqAssQusArr.filter((e) => e.key === chatBtnType)
    //   // } else {
    //   //   qusArr = reqAssQusArr
    //   // }
    //   // console.log(qusArr, chatBtnType)
    //   setQusCounter((prevCounter) => {
    //     const counter = prevCounter + 1;

    //     chatThreeDotLoading(true, null, [
    //       {
    //         chat_type: "select_yes_no",
    //         data: `Oh! I see, you need to write ${reqAssQusArr[counter]?.name} to apply on this scholarship. Do you need any help?`,
    //       },
    //     ]);
    //     return counter;
    //   });
    //   setCurrentRequirementIndex((prevCounter) => prevCounter + 1)

    // } else {
    //   setQusCounter(0);
    //   setEnableChat(false);

    //   setTimeout(() => {
    //     setShowChat(false);
    //   }, 1000);
    //   message.success(
    //     "Details have been successfully captured. Please add any additional details if required!!!"
    //   );
    // }
  };

  const handleNext = (data, dataType) => {
    // const key = dataType.field.dataType

    if (currentRequirementIndex < data.requirements.length - 1) {
      setCurrentRequirementIndex(currentRequirementIndex + 1);
      // if (dataType === 'editor' || dataType === 'text') {
      //   askNextQus()
      // }
      // setSaveAndNext(true);
    }
  };

  // useEffect(() => {
  //   console.log(filledData)
  // }, [filledData])

  return (
    <AssistanceChatBotContext.Provider
      value={{
        // incrementDailyFreeScholarshipLimit,stopRender, setStopRender
        stopRender,
        setStopRender,
        invitationPopUp,
        setInvitationPopUp,
        checkCanShare,
        canShare,
        setFreeScholarshipCount,
        freeScholarshipCount,
        wordLengthData,
        setWordLengthData,
        askNextQus,
        chatThreeDotLoading,
        handleNext,
        saveAndNext,
        setSaveAndNext,
        currentRequirementIndex,
        setCurrentRequirementIndex,
        first,
        setFirst,
        applyScholarshipId,
        setApplyScholarshipId,
        chatAssBtn,
        setChatAssBtn,
        hint,
        setHint,
        reqAssMaxView,
        setReqMaxView,
        mobReqAssMaxView,
        setMobReqMaxView,
        showChat,
        setShowChat,
        chatBtnType,
        setChatBtnType,
        reqAssQusArr,
        setReqQusArr,
        filledData,
        setFilledData,
        forceStopRender,
        setForceStopREnder,
        chatBtn,
        setChatBtn,
        localData,
        setLocalData,
        assMessages,
        setAssMessages,
        qusCounter,
        setQusCounter,
        runTour,
        setRunTour,
        steps,
        startTour,
        endTour,
        chatType,
        setChatTypeFn,
        messages,
        setMessages,
        render,
        setRender,
        startChat,
        setStartChat,
        hideIcons,
        setHideIcons,
        enableChat,
        setEnableChat,
        maximizeView,
        setMaximizeView,
        loading,
        setLoading,
        data,
        setData,
        citizenship,
        setCitizenship,
        ethnicity,
        setEthnicity,
        schoolLevel,
        setSchoolLevel,
        militaryAffiliation,
        setMilitaryAffiliation,
        gpa,
        setGpa,
        highSchoolName,
        setHighSchoolName,
        collegeName,
        setCollegeName,
        fieldOfStudy,
        setFieldOfStudy,
        degreeType,
        setDegreeType,
        careerGoal,
        setCareerGoal,
        citizenshipId,
        setCitizenshipId,
        ethnicityId,
        setEthnicityId,
        countryId,
        setCountryId,
        highSchoolNameId,
        setHighSchoolNameId,
        militaryAffiliationId,
        setMilitaryAffiliationId,
        gpaId,
        setGpaId,
        degreeTypeId,
        setDegreeTypeId,
        careerGoalId,
        setCareerGoalId,
        highSchoolGradDateId,
        setHighSchoolGradDateId,
        enrolledInCollegeId,
        setEnrolledInCollegeId,
        collegeEnrolledDateId,
        setCollegeEnrolledDateId,
        collegeNameId,
        setCollegeNameId,
        genderId,
        setGenderId,
        fieldOfStudyId,
        setFieldOfStudyId,
        currentSchoolLevelId,
        setCurrentSchoolLevelId,
        zipCodeId,
        setZipCodeId,
        collegeGraduationDateId,
        setCollegeGraduationDateId,
        interestedInOnlineId,
        setInterestedInOnlineId,
        interestedCollegesId,
        setInterestedCollegesId,
        numberForCountryName,
        setNumberForCountryName,
        country,
        setCountry,
        hideNavAlert,
        setHideNavAlert,
        handleScrollToTop,
        topRef,
      }}
    >
      {children}
    </AssistanceChatBotContext.Provider>
  );
};

const useAssitanceChatBotContext = () => {
  return useContext(AssistanceChatBotContext);
};

export { AssistanceChatBotProvider, useAssitanceChatBotContext };
