import React, { useState, useEffect, memo } from "react";
import { Typography } from "antd";
import registerStyle from "./registerStyle.js";

import Utils from "utils/common";
import { UserAuthService } from "services/user-auth.service";
import { API_BASE_URL } from "configs/AppConfig.js";

const EligibilityStats = () => {
  const mobileView = window.innerWidth < 961;
  const { Text } = Typography;

  const originaleligibiltyValues = localStorage.getItem("register2")
    ? JSON.parse(localStorage.getItem("register2"))
    : {};
  const fieldMappings = {
    "b9a172af-372b-4208-9036-470f5ff39b9b": "currentSchoolLevel",
    "6a9b2a3d-30f8-4980-af37-0cec301577e9": "fieldOfStudy",
    "8cba9cc2-3813-11ee-9f7a-16dca43b6f0b": "dob",
  };

  // Create updated eligibilityValues object
  const eligibilityValues = {};
  for (const fieldId in fieldMappings) {
    const originalField = fieldMappings[fieldId];
    eligibilityValues[`field.${fieldId}`] =
      originaleligibiltyValues[originalField];
  }

  // );

  const [stats, setStatsData] = useState({
    counts: 0,
    totalValue: 0,
  });

  const getEligibilityStats = async () => {
    const filter = {
      eligiblityFilters: eligibilityValues,
    };
    const url = `${API_BASE_URL}api/v1/scholarships/eligible-scholarships/stats?eligiblityFilters=${JSON.stringify(
      filter.eligiblityFilters
    )}`;

    const resp = await fetch(url, {
      method: "GET",
    });

    const data = await resp.json();
    // console.log("🚀 ~ getEligibilityStats ~ data:", data);
    setStatsData(data?.data);

    // return UserAuthService.getEligibilityStats(filter)
    //     .then((response) => {
    //         // console.log("fields data ", response);
    //         setStatsData(response.data);
    //     })
    //     .finally();
  };

  const showText1 = () => {
    return (
      <Text
        style={{
          ...registerStyle.headingText,
          padding: mobileView ? "0 5%" : "0 15%",
        }}
      >
        {/* You have the opportunity to apply for numerous scholarships, worth of
            millions awaiting you! */}
        We would need more detail in order to give you a perfects scholarship!
      </Text>
    );
  };

  const showText = () => {
    // console.log("stats value ", stats.counts);
    const formattedValue = Utils.formatAsUSD(stats.totalValue);
    return (
      <>
        <Text
          style={{
            ...registerStyle.headingText,
            padding: mobileView ? "0 5%" : "0 15%",
          }}
        >
          You qualify to apply for up to{" "}
        </Text>
        <Text
          style={{
            ...registerStyle.headingText,
            padding: mobileView ? "0 5%" : "0 15%",
            fontWeight: "600",
          }}
        >
          {stats?.counts} scholarships, with a total value of {formattedValue}
          <Text
            style={{
              ...registerStyle.headingText2,
              padding: mobileView ? "0 1%" : "0 1%",
            }}
          ></Text>
        </Text>
      </>
    );
  };

  useEffect(() => {
    // mixpanel.track("User Signup Page visited");
    getEligibilityStats();
  }, []);
  return (
    <>
      <h2
        style={{
          ...registerStyle.congratulations,
          // width: mobileView ? "90%" : "70%",
          margin: mobileView ? "20px auto 0" : "16px 0 0 0 ",
          fontSize: mobileView ? "24px" : "36px",
        }}
      >
        Good News!!!
      </h2>
      {stats?.counts > 0 ? showText() : showText1()}

      <p
        style={{
          ...registerStyle.congratulations,
          // width: mobileView ? "90%" : "70%",
          margin: mobileView ? "20px auto 0" : "20px auto",
          fontSize: mobileView ? "18px" : "22px",
          fontWeight: "500",
          color: "#1F32D9",
          backgroundColor: "#E0ECFF",
          textAlign: "center",
          width: mobileView ? "95%" : "75%",
          borderRadius: "8px",
          lineHeight: "normal",
          padding: "8px 0",
        }}
      >
        Why register for one if you can apply to many
      </p>
    </>
  );
};

export default memo(EligibilityStats);
