import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FirstThankYouPopup = ({ setFirstScholarshipPop }) => {
  const mobileView = window.innerWidth < 750;
  const history = useHistory();

  return (
    <div>
      <div>
        <h1
          style={{
            textAlign: "center",
            marginTop: "25px",
          }}
        >
          🎉 Unlock Free Scholarships Daily 🎉
        </h1>
        <div
          style={{
            width: mobileView ? "200px" : "240px",
            margin: "auto",
          }}
        >
          <img
            src="/new/invitationRobo.png"
            alt="robo"
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontWeight: "500",
            color: "black",
            fontSize: mobileView ? "14px" : "18px",
            margin: "0",
            textAlign: "center",
            maxWidth: window.innerWidth < 800 ? "auto" : "60%",
          }}
        >
          With each daily login, you{" "}
          <span
            style={{
              color: "#7879FF",
            }}
          >
            {" "}
            unlock 3 scholarships{" "}
          </span>
          —completely free! Just log in every day to explore new opportunities
          for funding your education.
        </p>

        <button
          style={{
            borderRadius: "8px",
            width: "max-content",
            padding: "8px 25px",
            backgroundColor: "rgb(141, 150, 255)",
            color: "white",
            fontSize: mobileView ? "13px" : "17px",
            fontWeight: "500",
            lineHeight: "normal",
            marginBottom: "15px",
            border: "none",
            cursor: "pointer",
            marginTop: "12px",
          }}
          onClick={() => {
            setFirstScholarshipPop(false);
            history.push("/dashboard");
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default FirstThankYouPopup;
