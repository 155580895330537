import { Grid, Layout } from "antd";
import Footer from "components/layout-components/Footer";
import HeaderNav from "components/layout-components/HeaderNav";
import MobileNav from "components/layout-components/MobileNav";
import PageHeader from "components/layout-components/PageHeader";
import SideNav from "components/layout-components/SideNav";
import Loading from "components/shared-components/Loading";
import navigationConfig from "configs/NavigationConfig";
import {
  DIR_LTR,
  DIR_RTL,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import React, { useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import utils from "utils";
import AuthViews from "views/auth-views";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AuthLayout = ({ navCollapsed, navType, location, direction }) => {
  // console.log("nav collapsed", navCollapsed);
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location?.pathname
  );
  const history = useHistory();
  const screens = utils.getBreakPoint(useBreakpoint());
  const mobileView = window.innerWidth < 1200;
  const { topRef } = useAssitanceChatBotContext();

  const [sideBarShow, setSideBars] = useState(
    [
      "/auth/scholarshipDetail",
      "/auth/message",
      "/auth/uploadEssay",
      "/auth/dashboard/success",
    ].includes(history.location.pathname)
      ? true
      : false
  );
  const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return 0;
  };

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return {
        paddingLeft: getLayoutGutter(),
        width: "-webkit-fill-available",
      };
    }
    if (direction === DIR_RTL) {
      return {
        paddingRight: getLayoutGutter(),
        width: "-webkit-fill-available",
      };
    }
    return {
      paddingLeft: getLayoutGutter(),
      // width: "-webkit-fill-available"
    };
  };

  const loggedInUser = () => {
    return (
      <Layout>
        <HeaderNav isMobile={isMobile} sideBarShow={sideBarShow} />
        <Layout className="app-container">
          {isNavSide && !isMobile ? (
            sideBarShow ? null : (
              <SideNav routeInfo={currentRouteInfo} />
            )
          ) : null}
          <Layout
            className="app-layout"
            style={sideBarShow ? {} : getLayoutDirectionGutter()}
          >
            <div
              className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}
              // ref={topRef}
              style={{
                //padding: mobileView ? "0px" : "124px",
                paddingLeft:
                  !process.env.NODE_ENV ||
                  process.env.NODE_ENV === "development"
                    ? isMobile
                      ? "5px"
                      : "10px"
                    : isMobile
                    ? "5px"
                    : sideBarShow
                    ? "10px"
                    : navCollapsed
                    ? "100px"
                    : "260px",
                height: "100%",
                height: "calc(100vh - 80px)",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <PageHeader
                display={currentRouteInfo?.breadcrumb}
                title={currentRouteInfo?.title}
              />
              <Content
                style={{
                  height: "100%",
                }}
                // ref={topRef}
              >
                <AuthViews setSideBars={setSideBars} />
              </Content>
            </div>
            <Footer />
          </Layout>
        </Layout>
        {isMobile && <MobileNav />}
      </Layout>
    );
  };

  const loggedOutUser = () => {
    return (
      <Switch>
        <Route path={"/" || "/auth" || "/app"} component={AuthViews} />
      </Switch>
    );
  };

  const viewSelector = [
    "/",
    "/home",
    "/b2b",
    "/auth/",
    "/auth/login",
    "/auth/register",
    "/auth/register1",
    "/signup",
    "/auth/userdetails",
    "/auth/apply",
    "/privacy",
    "/terms",
    "/faq",
    "/contact",
    "/partners",
    "/pricing",
    "/about-us",
    "/chatbot",
    "/forgot-password",
    "/lennyAssistance",
    "/unsubscribe",
    "/lenny-terms-policy",
    "/do-not-sell-my-personal-information",
  ].includes(history.location.pathname);
  return !viewSelector ? loggedInUser() : loggedOutUser();
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  // console.log("theme", theme);
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AuthLayout));
