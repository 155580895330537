import { Row, Col, Menu } from "antd";
import footerStyle from "./footerStyle";
export default function Footer() {
  const mobileView = window.innerWidth < 481;
  const footerRow1 = [
    { name: "Contact", redirect: "/contact" },
    { name: "FAQ", redirect: "/faq" },
    { name: "Unsubscribe", redirect: "/unsubscribe" },
    {
      name: "Lenny Scholarship Page",
      redirect: "https://lennyscholarship.applychimp.com/",
    },
  ];
  const footerRow2 = [
    { name: "Brands & Businesses", redirect: "https://business.lenny.ai" },
    { name: "Partner", redirect: "/partners" },
    { name: "Sign UP", redirect: "/signup" },
  ];
  const footerRow3 = [
    //{name: "California Policy", redirect: ""},
    { name: "Terms of use", redirect: "/terms" },
    { name: "Privacy Policy", redirect: "/privacy" },
    {
      name: "Lenny's Scholarship Terms & Policies",
      redirect: "/lenny-terms-policy",
    },
    // {name: "EU Privacy/GDPR", redirect: ""},
  ];

  return (
    <Row style={footerStyle.footer}>
      <div style={{ width: mobileView ? "" : "20%" }}>
        <img
          src={"/applicantImg/LennyFooterLogo.png"}
          alt="Lenny.AI Your First Scholarship Solutions"
          style={footerStyle.footerImage}
        />
      </div>
      <Menu mode={"vertical"} style={footerStyle.footerMenu}>
        {footerRow1.map((items) => (
          <Menu.Item>
            <a href={items.redirect} style={footerStyle.footerText}>
              {items.name}
            </a>
          </Menu.Item>
        ))}
      </Menu>
      <Menu mode={"vertical"} style={footerStyle.footerMenu}>
        {footerRow2.map((items) => (
          <Menu.Item>
            <a href={items.redirect} style={footerStyle.footerText}>
              {items.name}
            </a>
          </Menu.Item>
        ))}
      </Menu>
      <Menu mode={"vertical"} style={footerStyle.footerMenu}>
        {footerRow3.map((items) => (
          <Menu.Item>
            <a href={items.redirect} style={footerStyle.footerText}>
              {items.name}
            </a>
          </Menu.Item>
        ))}
      </Menu>
      <div style={{}}>
        <div style={footerStyle.footerText}>Follow us on</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "8px",
          }}
        >
          <a href="https://www.instagram.com/lennystudentai/" target="_blank">
            <img
              src="/new/insta.png"
              alt="Instagram"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61564470073435"
            target="_blank"
          >
            <img
              src="/new/fbIcon.png"
              alt="Instagram"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </a>
        </div>
      </div>
      <div style={{ marginTop: "8px" }}>
        <div style={footerStyle.footerText}>
          Copyright Lenny.AI © 2024. All Rights Reserved
        </div>
        <div style={{ ...footerStyle.footerText, marginTop: "8px" }}>
          No purchase necessary to enter or apply for any scholarship or
          sweepstakes.
        </div>
        <div style={{ ...footerStyle.footerText, marginTop: "8px" }}>
          Lenny is a personalized AI filtering, matching, management, and
          application assistant. Lenny does not guarantee the receipt of
          financial aid nor does upgrading your membership increase your chances
          of gaining any particular award, reward or acceptance.
        </div>
      </div>
    </Row>
  );
}
